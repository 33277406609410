<template>
  <v-card elevation="1" class="fill-height" :class="personal ? 'px-10 pt-5' : 'px-10 pt-3'" min-height="280">
    <v-dialog v-model="show_dialog" fullscreen>
      <v-dialog
          fullscreen
          v-model="preview"
      >

        <div class="image-wrapper">
          <div class="image-content" v-if="!is_pdf">
            <v-img
                aspect-ratio="1"
                :src="file_url"
                max-height="90vh"
                style="position: relative"
            >
              <v-btn @click="preview = false; is_pdf = false"
                     class="white"
                     depressed
                     style="position: absolute; top: 0; right: 0"
                     :v-ripple="false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-img>
          </div>
        </div>

      </v-dialog>
      <v-dialog v-model="change_status" width="400">
        <v-card>
          <v-card-text class="py-4 pt-5">
            <v-select :items="appeal_status_type_admin" color="grey" filled
                      label="Новий статус звернення"
                      v-model="item_object.status_type_new"
                      :class="!item_object.status_type_new ? 'req-star' : ''"
                      hide-details/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed small @click="changeStatusCrud"
                   :disabled="!item_object.status_type_new">
              Змінити статус
            </v-btn>
            <v-btn depressed small @click="closeChangeStatusDialog">Відмінити</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="show_rate" width="400" persistent>
        <v-card>
          <v-card-text class="pt-4 pb-1 text-center" style="font-size: 1rem">
            Оцініть якість виконання даного звернення
          </v-card-text>
          <v-card-text class="text-center pb-1">
            <v-rating
                v-model="rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
                x-large
                hover
                large
            ></v-rating>
          </v-card-text>
          <v-card-text class="text-center pt-2">
            <v-icon :color="getRateIcon('color')" size="46">
              {{ getRateIcon('icon') }}
            </v-icon>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed small @click="doRate">
              Оцінити
            </v-btn>
            <v-btn depressed small @click="closeRateDialog">Відмінити</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card style="display: flex; flex-direction: column;">
        <div class="d-flex" style="height: 28px; flex: 0">
          <v-btn depressed small @click="closeDialog">
            <v-icon small left>mdi-arrow-left</v-icon>
            Повернутись назад
          </v-btn>
          <v-spacer></v-spacer>
        </div>
        <div class="px-4 pt-3" style="flex: 0" v-if="item_object.id">
          <div style="display: flex; flex-wrap: wrap"
               :style="$vuetify.breakpoint.mdAndUp ? 'flex-direction: row;' : 'flex-direction: column;'">
            <div style="flex: 1">
              <v-chip-group :column="$vuetify.breakpoint.smAndDown">
                <v-chip label>
                  <v-icon left size="20">mdi-calendar</v-icon>
                  {{ item_object.create_date | formatDate }}
                </v-chip>

                <v-chip label>
                  <v-icon left size="20">mdi-account</v-icon>
                  {{ item_object.receiver_name }}
                </v-chip>

                <v-chip label :color="getAppealIcon(item_object.status_type, 'color')" dark>
                  <v-icon left size="20">
                    {{ getAppealIcon(item_object.status_type, 'icon') }}
                  </v-icon>
                  {{ getAppealIcon(item_object.status_type, 'text') }}
                </v-chip>

                <v-chip label color="grey lighten-5" v-if="item_object.rating">
                  <v-icon size="22" color="yellow darken-2" class="mr-1">mdi-star</v-icon>
                  {{ item_object.rating }}
                </v-chip>

              </v-chip-group>
            </div>
            <div style="flex: 0 0 120" v-if="personal && $vuetify.breakpoint.mdAndUp">
              <v-btn depressed :color="newItemReady ? 'teal' : 'grey lighten-4'"
                     :dark="newItemReady" :disabled="!newItemReady"
                     @click="publishNewItem"
                     :loading="loading"
              >
                Переопублікувати
              </v-btn>
            </div>
            <div style="flex: 1; width: 100%; display: flex; align-items: center" v-if="!personal && $vuetify.breakpoint.smAndDown">
              <div style="flex: 1">
                <v-btn depressed :color="newItemReady ? 'teal' : 'grey lighten-4'"
                       :dark="newItemReady" :disabled="!newItemReady"
                       @click="publishNewItem"
                       :loading="loading"
                       v-if="personal"
                       block
                >
                  Переопублікувати
                </v-btn>
                <v-btn block depressed color="teal" dark
                       v-if="!personal && !(item_object.status_type === 'closed')"
                       @click="changeAppealStatus"
                >
                  Змінити статус
                </v-btn>
              </div>
              <div style="flex: 0 0 60px" v-if="personal">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" depressed class="ml-2">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        :disabled="item_object.status_type === 'closed'"
                        v-if="personal" @click="closeAppeal"
                    >
                      <v-list-item-icon>
                        <v-icon color="success">mdi-format-list-bulleted</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Закрити звернення
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        :disabled="item_object.status_type !== 'closed'"
                        v-if="personal" @click="repeatAppeal"
                    >
                      <v-list-item-icon>
                        <v-icon color="success">mdi-format-list-bulleted</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Відкрити повторно
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
          <v-card elevation="3" class="px-4 py-5 mt-2" style="position:relative;">
            <div style="display: flex" v-if="!item_object.edit">
              <div style="flex: 1">
                {{ item_object.text }}
              </div>
              <div style="flex: 0 0 60px" class="align-center" v-if="personal">
                <v-btn icon color="success" @click="item_object.edit = !item_object.edit">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </div>
            <div style="display: flex" v-else>
              <div style="flex: 1">
                <v-textarea v-model="item_object.text" hide-details
                            color="grey" rows="3"
                            :class="!item_object.text ? 'req-star' : ''"
                            label="Суть звернення"
                />
              </div>
              <div style="flex: 0 0 60px;" class="align-center" v-if="personal">
                <v-btn icon color="success" @click="item_object.edit = !item_object.edit">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
        <div v-else class="px-4 pt-3" style="flex: 0; display: flex; flex-wrap: wrap">
          <v-col cols="12">
            <v-card class="pa-3">
              <div style="display: flex; align-items: center">
                <div style="font-size: 1.2rem">Створення нового звернення</div>
                <v-spacer></v-spacer>
                <div>
                  <v-btn depressed @click="publishNewItem" :disabled="!newItemReady" :loading="loading">Опублікувати</v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="receivers" v-model="item_object.receiver_id"
                      filled hide-details
                      color="grey" :class="!item_object.receiver_id ? 'req-star' : ''"
                      label="Отримувач" clearable
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea v-model="item_object.text" filled rows="4"
                        color="grey" hide-details
                        :class="!item_object.text ? 'req-star' : ''"
                        label="Суть звернення" clearable/>
          </v-col>
        </div>
        <div class="px-4 pt-3 d-flex flex-wrap" style="flex: 1" v-if="item_object.id">
          <div style="position:relative; display: flex; width: 100%" v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="12" md="9" sm="8" class="pl-0">
              <v-card class="mt-0 mb-2 grey lighten-3" tile elevation="0"
                      :disabled="!item_object.id"
                      style="flex: 1; overflow-y: auto; overflow-x: hidden; height: calc(100% - 50px); padding: 8px"
                      id="chat"
              >
                <v-row id="chat-body">
                  <template v-for="(item, idx) in comments">
                    <v-col cols="3" :key="`${idx}-s`" v-if="item.self" style="">
                      <div style="font-size: .8rem; text-align: right">{{ item.create_date | formatDate }}</div>
                    </v-col>
                    <v-col cols="9" class="pb-1"
                           :key="idx"
                           :style="item.self ? 'padding-left: 2px' : 'padding-right: 2px'"
                    >
                      <v-card
                          :shaped="!item.self"
                          :class="item.self ? 'grey lighten-2 pt-1 px-2 pb-2' : 'grey lighten-5 pt-3 px-2 pb-2'"
                          style="position: relative"
                      >
                        <div v-if="!item.self">
                          <v-chip small
                                  label
                                  :color="item.position ? 'secondary lighten-1' : 'grey'"
                                  dark
                                  class="py-1"
                                  style="position: absolute; top: -2px; left: -1px;">
                            {{ item.position ? item.position : item.address }}
                          </v-chip>
                        </div>
                        <div
                            :style="item.self ? 'margin-top: 2px' : 'margin-top: 13px'"
                            style="font-size: .85rem"
                        >
                          {{ item.text }}
                        </div>
                      </v-card>
                    </v-col>
                    <v-col cols="3" :key="`${idx}-s`" v-if="!item.self">
                      <div style="font-size: .8rem; text-align: left">{{ item.create_date | formatDate }}</div>
                    </v-col>
                  </template>
                </v-row>
              </v-card>
              <v-card style="flex: 0" class="mb-2">
                <div style="display: flex; align-items: center">
                  <div style="flex: 1">
                    <v-text-field single-line rounded hide-details v-model="item_object.message"
                                  placeholder="Введіть повідомлення"
                                  class="pt-0"
                                  :disabled="!item_object.id"
                                  @keypress.enter="addComment"
                    />
                  </div>
                  <div style="flex: 0 0 39px">
                    <v-btn icon small :disabled="!item_object.message" @click="addComment">
                      <v-icon>mdi-send</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="4">
              <div class="d-flex" v-if="!personal && !(item_object.status_type === 'closed')">
                <v-btn block depressed class="mb-3 mr-2" color="teal" dark
                       v-if="!personal && !(item_object.status_type === 'closed')"
                       style="flex: 1; min-width: 65% !important;"
                       @click="changeAppealStatus">
                  Змінити статус
                </v-btn>
                <label :for="`my-file-Input-${item_object.id}-1`"
                       class="success v-btn v-btn--has-bg theme--light v-btn--block theme--dark v-size--default teal"
                       style="cursor: pointer; min-width: 34% !important;"
                       v-if="!personal && !(item_object.status_type === 'closed')"
                >
                  <v-icon size="20" dark>mdi-file-image-plus-outline</v-icon>
                </label>
                <v-file-input
                    multiple
                    hide-input
                    hide-details
                    :id="`my-file-Input-${item_object.id}-1`"
                    style="display: none"
                    :key="item_object.id"
                    @change="changeFileOutgoing"
                />
              </div>
              <v-btn block depressed class="mb-3" color="grey lighten-3"
                     :loading="loading"
                     :disabled="item_object.status_type === 'closed'"
                     v-if="personal" @click="closeAppeal">
                Закрити звернення
              </v-btn>
              <v-btn block depressed class="mb-3"
                     :loading="loading"
                     :disabled="item_object.status_type !== 'closed'"
                     v-if="personal" @click="repeatAppeal">
                Відкрити повторно
              </v-btn>
              <v-tabs class="custom-tabs" color="success">
                <v-tab>Файли</v-tab>
                <v-tab>Статуси</v-tab>

                <v-tab-item class="pa-2 mt-2">
                  <v-row class="flex-column fill-height">
                    <v-col cols="12" style="flex: 0 0 50%">
                      <v-row>
                        <v-col cols="12" v-if="item_object.files.filter(i => !i.additional_condition_integer)">
                          {{ personal ? 'Надіслані:' : 'Отримані:' }}
                        </v-col>
                        <v-col cols="12" sm="12" md="12"
                               class="pa-0 pb-2"
                               v-for="file in item_object.files.filter(i => !i.additional_condition_integer)" :key="`c-file-${item_object.id}-idx--${file.id}`">
                          <v-card class="mx-2">
                            <v-list-item>
                              <v-list-item-icon class="mr-3" @click="preview_image(file)" style="cursor: pointer">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="26" v-if="file" v-on="on" v-bind="attrs">
                                      {{ getFileIcon(file.file_ext) }}
                                    </v-icon>
                                  </template>
                                  <span>Попередній перегляд</span>
                                </v-tooltip>

                              </v-list-item-icon>
                              <v-list-item-content class="py-2">
                                <v-list-item-title
                                    :style="file.delete ? 'text-decoration: line-through' : ''"
                                    class="grey--text"
                                    style="cursor: pointer; text-decoration: underline; font-size: .81rem"
                                >
                                  <a :href="getFile(file)" :download="`${file.file_name_origin}`" target="_blank"
                                     rel="noopener noreferrer" v-if="!file.binary">
                                    {{ file.file_name_origin }}
                                  </a>
                                  <template v-else>
                                    {{ file.file_name_origin }}
                                  </template>
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    :style="file.delete ? 'text-decoration: line-through' : ''"
                                    style="font-size: .7rem"
                                >
                                  <template v-if="file.binary">
                                    Додано щойно
                                  </template>
                                  <template v-else>
                                    Завантажено: {{ file.create_date | formatDate }}
                                  </template>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn icon :class="file.delete ? 'success' : 'error lighten-1'" v-if="personal"
                                       @click.stop="deleteFile(file)">
                                  <v-icon color="white">
                                    {{ file.delete ? 'mdi-backup-restore' : 'mdi-delete' }}
                                  </v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" style="flex: 1" v-if="item_object.files.filter(i => i.additional_condition_integer).length">
                      {{ personal ? 'Отримані:' : 'Надіслані:' }}
                    </v-col>
                    <v-col cols="12" sm="12" md="12"
                           class="pa-0 pb-2"
                           v-for="file in item_object.files.filter(i => i.additional_condition_integer)" :key="`c-file-${item_object.id}-idx--${file.id}`">
                      <v-card class="mx-2">
                        <v-list-item>
                          <v-list-item-icon class="mr-3" @click="preview_image(file)" style="cursor: pointer">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon size="26" v-if="file" v-on="on" v-bind="attrs">
                                  {{ getFileIcon(file.file_ext) }}
                                </v-icon>
                              </template>
                              <span>Попередній перегляд</span>
                            </v-tooltip>

                          </v-list-item-icon>
                          <v-list-item-content class="py-2">
                            <v-list-item-title
                                :style="file.delete ? 'text-decoration: line-through' : ''"
                                class="grey--text"
                                style="cursor: pointer; text-decoration: underline; font-size: .81rem"
                            >
                              <a :href="getFile(file)" :download="`${file.file_name_origin}`" target="_blank"
                                 rel="noopener noreferrer" v-if="!file.binary">
                                {{ file.file_name_origin }}
                              </a>
                              <template v-else>
                                {{ file.file_name_origin }}
                              </template>
                            </v-list-item-title>
                            <v-list-item-subtitle
                                :style="file.delete ? 'text-decoration: line-through' : ''"
                                style="font-size: .7rem"
                            >
                              <template v-if="file.binary">
                                Додано щойно
                              </template>
                              <template v-else>
                                Завантажено: {{ file.create_date | formatDate }}
                              </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon :class="file.delete ? 'success' : 'error lighten-1'" v-if="!personal"
                                   @click.stop="deleteFileOutgoing(file)">
                              <v-icon color="white">
                                {{ file.delete ? 'mdi-backup-restore' : 'mdi-delete' }}
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pa-2 mt-2 grey lighten-4">
                  <v-row>
                    <v-col cols="12">
                      <v-timeline align-top dense v-if="item_object.id && item_object.history_status.length">
                        <v-timeline-item
                            v-for="history in item_object.history_status"
                            :key="`history-${history.id}`"
                            :color="getAppealIcon(history.status_type, 'color')" small>
                          <v-row class="pt-1">
                            <v-col cols="5">
                              <div style="font-size: .9rem">{{ history.create_date | formatDate }}</div>
                            </v-col>
                            <v-col>
                              <div style="font-size: .9rem">
                                {{ getAppealIcon(history.status_type, 'text') }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>

                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </div>
          <div style="position:relative; display: flex; width: 100%" v-else>
            <v-tabs class="custom-tabs" color="success" style="height: 100%">
              <v-tab>Звернення</v-tab>
              <v-tab>Файли</v-tab>
              <v-tab>Статуси</v-tab>

              <v-tab-item class="pa-2 mt-2" style="height: 100%">
                <v-row style="height: calc(100% + 56px)">
                  <v-col cols="12" class="pl-0">
                    <v-card class="mt-0 mb-2 grey lighten-3" tile elevation="0"
                            :disabled="!item_object.id"
                            style="flex: 1; overflow-y: auto; overflow-x: hidden; height: calc(100% - 50px); padding: 8px"
                            id="chat"
                    >
                      <v-row id="chat-body">
                        <template v-for="(item, idx) in comments">
                          <v-col cols="3" :key="`${idx}-s`" v-if="item.self" style="">
                            <div style="font-size: .8rem; text-align: right">{{ item.create_date | formatDate }}</div>
                          </v-col>
                          <v-col cols="9" class="pb-1"
                                 :key="idx"
                                 :style="item.self ? 'padding-left: 2px' : 'padding-right: 2px'"
                          >
                            <v-card
                                :shaped="!item.self"
                                :class="item.self ? 'grey lighten-2 pt-1 px-2 pb-2' : 'grey lighten-5 pt-3 px-2 pb-2'"
                                style="position: relative"
                            >
                              <div v-if="!item.self">
                                <v-chip small
                                        label
                                        :color="item.position ? 'secondary lighten-1' : 'grey'"
                                        dark
                                        class="py-1"
                                        style="position: absolute; top: -2px; left: -1px;">
                                  {{ item.position ? item.position : item.address }}
                                </v-chip>
                              </div>
                              <div
                                  :style="item.self ? 'margin-top: 2px' : 'margin-top: 13px'"
                                  style="font-size: .85rem"
                              >
                                {{ item.text }}
                              </div>
                            </v-card>
                          </v-col>
                          <v-col cols="3" :key="`${idx}-s`" v-if="!item.self">
                            <div style="font-size: .8rem; text-align: left">{{ item.create_date | formatDate }}</div>
                          </v-col>
                        </template>
                      </v-row>
                    </v-card>
                    <v-card style="flex: 0" class="mb-2">
                      <div style="display: flex; align-items: center">
                        <div style="flex: 1">
                          <v-text-field single-line rounded hide-details v-model="item_object.message"
                                        placeholder="Введіть повідомлення"
                                        class="pt-0"
                                        :disabled="!item_object.id"
                                        @keypress.enter="addComment"
                          />
                        </div>
                        <div style="flex: 0 0 39px">
                          <v-btn icon small :disabled="!item_object.message" @click="addComment">
                            <v-icon>mdi-send</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="pa-2 mt-2">
                <v-row class="flex-column fill-height">
                  <v-col cols="12" style="flex: 0 0 50%">
                    <v-row>
                      <v-col cols="12" v-if="item_object.files.filter(i => !i.additional_condition_integer)">
                        {{ personal ? 'Надіслані:' : 'Отримані:' }}
                      </v-col>
                      <v-col cols="12" sm="12" md="12"
                             class="pa-0 pb-2"
                             v-for="file in item_object.files.filter(i => !i.additional_condition_integer)" :key="`c-file-${item_object.id}-idx--${file.id}`">
                        <v-card class="mx-2">
                          <v-list-item>
                            <v-list-item-icon class="mr-3" @click="preview_image(file)" style="cursor: pointer">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon size="26" v-if="file" v-on="on" v-bind="attrs">
                                    {{ getFileIcon(file.file_ext) }}
                                  </v-icon>
                                </template>
                                <span>Попередній перегляд</span>
                              </v-tooltip>

                            </v-list-item-icon>
                            <v-list-item-content class="py-2">
                              <v-list-item-title
                                  :style="file.delete ? 'text-decoration: line-through' : ''"
                                  class="grey--text"
                                  style="cursor: pointer; text-decoration: underline; font-size: .81rem"
                              >
                                <a :href="getFile(file)" :download="`${file.file_name_origin}`" target="_blank"
                                   rel="noopener noreferrer" v-if="!file.binary">
                                  {{ file.file_name_origin }}
                                </a>
                                <template v-else>
                                  {{ file.file_name_origin }}
                                </template>
                              </v-list-item-title>
                              <v-list-item-subtitle
                                  :style="file.delete ? 'text-decoration: line-through' : ''"
                                  style="font-size: .7rem"
                              >
                                <template v-if="file.binary">
                                  Додано щойно
                                </template>
                                <template v-else>
                                  Завантажено: {{ file.create_date | formatDate }}
                                </template>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon :class="file.delete ? 'success' : 'error lighten-1'" v-if="personal"
                                     @click.stop="deleteFile(file)">
                                <v-icon color="white">
                                  {{ file.delete ? 'mdi-backup-restore' : 'mdi-delete' }}
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" style="flex: 1" v-if="item_object.files.filter(i => i.additional_condition_integer).length">
                    {{ personal ? 'Отримані:' : 'Надіслані:' }}
                  </v-col>
                  <v-col cols="12" sm="12" md="12"
                         class="pa-0 pb-2"
                         v-for="file in item_object.files.filter(i => i.additional_condition_integer)" :key="`c-file-${item_object.id}-idx--${file.id}`">
                    <v-card class="mx-2">
                      <v-list-item>
                        <v-list-item-icon class="mr-3" @click="preview_image(file)" style="cursor: pointer">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon size="26" v-if="file" v-on="on" v-bind="attrs">
                                {{ getFileIcon(file.file_ext) }}
                              </v-icon>
                            </template>
                            <span>Попередній перегляд</span>
                          </v-tooltip>

                        </v-list-item-icon>
                        <v-list-item-content class="py-2">
                          <v-list-item-title
                              :style="file.delete ? 'text-decoration: line-through' : ''"
                              class="grey--text"
                              style="cursor: pointer; text-decoration: underline; font-size: .81rem"
                          >
                            <a :href="getFile(file)" :download="`${file.file_name_origin}`" target="_blank"
                               rel="noopener noreferrer" v-if="!file.binary">
                              {{ file.file_name_origin }}
                            </a>
                            <template v-else>
                              {{ file.file_name_origin }}
                            </template>
                          </v-list-item-title>
                          <v-list-item-subtitle
                              :style="file.delete ? 'text-decoration: line-through' : ''"
                              style="font-size: .7rem"
                          >
                            <template v-if="file.binary">
                              Додано щойно
                            </template>
                            <template v-else>
                              Завантажено: {{ file.create_date | formatDate }}
                            </template>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon :class="file.delete ? 'success' : 'error lighten-1'" v-if="!personal"
                                 @click.stop="deleteFileOutgoing(file)">
                            <v-icon color="white">
                              {{ file.delete ? 'mdi-backup-restore' : 'mdi-delete' }}
                            </v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="pa-2 mt-2 grey lighten-4">
                <v-row  style="height: calc(100% + 56px)">
                  <v-col cols="12">
                    <v-timeline align-top dense v-if="item_object.id && item_object.history_status.length">
                      <v-timeline-item
                          v-for="history in item_object.history_status"
                          :key="`history-${history.id}`"
                          :color="getAppealIcon(history.status_type, 'color')" small>
                        <v-row class="pt-1">
                          <v-col cols="5">
                            <div style="font-size: .9rem">{{ history.create_date | formatDate }}</div>
                          </v-col>
                          <v-col>
                            <div style="font-size: .9rem">
                              {{ getAppealIcon(history.status_type, 'text') }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>

                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
        <div class="px-4 pt-3" v-if="!item_object.id">
          <div class="grey lighten-4">
            <div class="d-flex align-center pa-3">
              <label :for="`my-file-Input-${item_object.id}`"
                     class="success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small"
                     style="cursor: pointer"
              >
                <v-icon left size="20" dark>mdi-plus</v-icon>
                Новий файл
              </label>
              <v-file-input
                  multiple
                  hide-input
                  hide-details
                  :id="`my-file-Input-${item_object.id}`"
                  style="display: none"
                  :key="item_object.id"
                  @change="changeFile"
              />
            </div>
            <v-divider></v-divider>
            <div class="d-flex align-center flex-wrap">
              <v-col cols="12" sm="12" md="6" v-for="file in item_object.files"
                     :key="`c-file-${item_object.id}-idx--${file.id}`">
                <v-card>
                  <v-list-item>
                    <v-list-item-icon class="mr-3" @click="preview_image(file)" style="cursor: pointer">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon size="32" v-if="file" v-on="on" v-bind="attrs">
                            {{ getFileIcon(file.file_ext) }}
                          </v-icon>
                        </template>
                        <span>Попередній перегляд</span>
                      </v-tooltip>

                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                          :style="file.delete ? 'text-decoration: line-through' : ''"
                          class="grey--text"
                          style="cursor: pointer; text-decoration: underline; font-size: .93rem"
                      >
                        <a :href="getFile(file)" :download="`${file.file_name_origin}`" target="_blank"
                           rel="noopener noreferrer" v-if="!file.binary">
                          {{ file.file_name_origin }}
                        </a>
                        <template v-else>
                          {{ file.file_name_origin }}
                        </template>
                      </v-list-item-title>
                      <v-list-item-subtitle
                          :style="file.delete ? 'text-decoration: line-through' : ''"
                          style="font-size: .8rem"
                      >
                        <template v-if="file.binary">
                          Додано щойно
                        </template>
                        <template v-else>
                          Завантажено: {{ file.create_date | formatDate }}
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon :class="file.delete ? 'success' : 'error lighten-1'" @click.stop="deleteFile(file)">
                        <v-icon color="white">
                          {{ file.delete ? 'mdi-backup-restore' : 'mdi-delete' }}
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="!item_object.files.length">
                <div class="empty-files">
                  Вкладені файли відсутні. Натисніть "Новий файл"
                </div>
              </v-col>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="show_filter_dialog" width="500" persistent>
      <v-card>
        <v-card-text class="py-4">
          <div class="mb-1">Відбір за статусом</div>
          <v-divider></v-divider>
          <div class="mb-3">
            <v-checkbox class="mt-1" label="Показувати 'Нові'" v-model="filters.new" :ripple="false" hide-details color="success"/>
            <v-checkbox class="mt-1" label="Показувати 'У процесі'" v-model="filters.progress" :ripple="false" hide-details color="success"/>
            <v-checkbox class="mt-1" label="Показувати 'Виконані'" v-model="filters.done" :ripple="false" hide-details color="success"/>
            <v-checkbox class="mt-1" label="Показувати 'Відхилені'" v-model="filters.reject" :ripple="false" hide-details color="success"/>
            <v-checkbox class="mt-1" label="Показувати 'Поторні'" v-model="filters.repeat" :ripple="false" hide-details color="success"/>
            <v-checkbox class="mt-1" label="Показувати 'Закриті'" v-model="filters.closed" :ripple="false" hide-details color="success"/>
          </div>
          <div class="mb-1">Відбір за датою</div>
          <v-divider></v-divider>
          <div class="mt-3 d-flex">
            <date-component class_="mr-1" label="Період з" v-model="filters.date_start"/>
            <date-component class_="ml-1" label="Період по" v-model="filters.date_end"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed small @click="setFilter">
            Відібрати
          </v-btn>
          <v-btn depressed small @click="closeFilterDialog">Відмінити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="personal">
      <v-col cols="12">
        <v-btn depressed small outlined color="success" @click="show_dialog = true" v-if="!disabled">
          <v-icon left>mdi-plus</v-icon>
          Нове звернення
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card elevation="3" class="px-0 py-0">
          <v-toolbar elevation="0" class="pa-0">
            <v-toolbar-title>
              Список звернень від мешканців
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon class="grey lighten-4" @click="openFilterDialog">
              <v-icon size="26">
                mdi-filter-settings-outline
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" xl="3"
             v-for="item in items" :key="item.id"
      >
        <v-card max-width="500" elevation="3" class="pa-2" @click="onAppealClick(item)" :disabled="disabled">
          <div class="top-appeal-line">
            <div class="date-receiver">
              <div class="receiver" v-if="personal">
                {{ item.receiver_name }}
              </div>
              <div class="receiver" v-else>
                <span class="font-weight-medium">{{ item.receiver_name }},</span> {{ item.anonymously ? '' : item.address }}
              </div>
              <div class="d-flex mt-1">
                <v-chip label small class="mr-2">
                  {{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
                </v-chip>
                <v-chip label small :color="getAppealIcon(item.status_type, 'color')" dark>
                  <v-icon size="16" left>
                    {{ getAppealIcon(item.status_type, 'icon') }}
                  </v-icon>
                  {{ getAppealIcon(item.status_type, 'text') }}
                </v-chip>
              </div>
            </div>
            <v-spacer/>
            <div class="stars" v-if="item.rating">
              <div class="star-icon">
                <v-icon size="22" color="yellow darken-2" class="mr-1">mdi-star</v-icon>
              </div>
              <div class="star-value">{{ item.rating }}</div>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="text-appeal-line" :style="$vuetify.breakpoint.sm ? 'height: 110px !important;' : ''">
            {{ item.text.length >= 150 ? item.text.substr(0, 147) + '...' : item.text }}
          </div>
          <div class="bottom-appeal-line">
            <div class="bottom-items">
              <div class="bottom-item">
                <v-icon size="19" color="success" class="mr-1">mdi-comment-text-multiple-outline</v-icon>
                {{ item.comments_count || 0 }}
              </div>
              <div class="bottom-item">
                <v-icon size="26" color="primary" class="mr-1">mdi-attachment</v-icon>
                {{ item.files_count || 0 }}
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="edit-menu">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" class="dotted-button grey lighten-4">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense nav>
                  <v-list-item v-if="item.person_hash && !personal && !item.anonymously"
                               :to="{name: 'WorkPlace', params: {person_hash: item.person_hash}}"
                  >
                    <v-list-item-title>Перейти до абонента</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onItemEdit(item)">
                    <v-list-item-title>Редагувати</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onItemDelete(item)"
                               :disabled="!(item.status_type === 'new' && item.comments_count === 0)">
                    <v-list-item-title>Вилучити</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import {
  APPEAL_COMMENT_CREATE,
  APPEAL_COMMENT_GET_ALL,
  APPEAL_ITEM_CREATE,
  APPEAL_ITEM_GET_ALL,
  APPEAL_CLEAR_COMMENTS,
  APPEAL_ITEM_UPDATE,
  APPEAL_UPDATE_COMMENT_COUNT, APPEAL_ITEM_REMOVE, APPEAL_ITEM_RATE, APPEAL_FILE_UPDATE
} from "@/store/actions/appeal";
import {mapActions, mapGetters} from "vuex";
import {maxBy} from "lodash";
import {appeal_status_type_admin, ext_obj, beginOfMonth, addMothsToStringDate} from "@/utils/icons";
import {ALERT_SHOW} from "@/store/actions/alert";
import {BASE_URL_AXIOS} from "@/utils/axios";
import {appeal_status_type, getAppealIcon} from "@/utils/icons"
import {QUESTION_SHOW} from "@/store/actions/question";
import {SELECT_APPEAL_RECEIVER_GROUP} from "@/store/actions/appeal_receiver_group";
import {GET_CURRENT_MONTH} from "@/store/actions/work_month";
// import pdf from "vue-pdf";

export default {
  name: "Appeal",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    person_hash: {
      type: String,
      default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    }
  },
  components: {
    // pdf
  },
  computed: {
    ...mapGetters(
        {
          items: "getAppeals",
          comments: "getAppealComments",
          modalAnswer: 'question_answer',
          socket_data: 'get_socket',
          profile: 'getProfile',
          organization: 'getCurrentOrganization',
          receivers: 'getSelectAppealReceiverGroups',
          flat: 'getCurrentUserFlat',
          current_month: 'getCurrentMonth'
        }
    ),
    newItemReady() {
      return !!(this.item_object.text && this.item_object.receiver_id)
    }
  },
  data() {
    return {
      loading: false,
      change_status: false,
      appeal_status_type,
      appeal_status_type_admin,
      show_dialog: false,
      show_rate: false,
      show_filter_dialog: false,
      item_object: {
        message: '',
        create_date: null,
        receiver_id: null,
        receiver_name: '',
        text: '',
        rating: null,
        id: null,
        files: [],
        edit: false,
        status_type: null,
        status_type_new: null,
        its_owner: false
      },
      delete_watcher: null,
      rating: null,
      preview: false,
      file_url: '',
      is_pdf: false,
      filters: {
        date_start: null,
        date_end: null,
        new: false,
        progress: false,
        done: false,
        reject: false,
        repeat: false,
        closed: false
      },
    }
  },
  methods: {
    getAppealIcon,
    ...mapActions(
        {
          fetch_appeals: APPEAL_ITEM_GET_ALL,
          fetch_comments: APPEAL_COMMENT_GET_ALL,
          getCurrentMonth: GET_CURRENT_MONTH
        }
    ),
    getPayloadForFetch() {
      const payload = {
        date_start: this.filters.date_start,
        date_end: this.filters.date_end,
        admin: !this.personal
      }

      const status = []

      if (this.filters.new) {
        status.push('new')
      }
      if (this.filters.progress) {
        status.push('progress')
      }
      if (this.filters.reject) {
        status.push('reject')
      }
      if (this.filters.repeat) {
        status.push('repeat')
      }
      if (this.filters.closed) {
        status.push('closed')
      }
      payload.status = status

      return payload
    },
    fetchAppealData() {
      const payload = this.getPayloadForFetch()
      this.fetch_appeals(payload)
    },
    setFilter() {
      this.fetchAppealData()
      this.show_filter_dialog = false
    },
    openFilterDialog() {
      this.show_filter_dialog = true
    },
    closeFilterDialog() {
      this.show_filter_dialog = false
    },
    getRateIcon(type) {
      const payload = {
        0: {icon: 'mdi-emoticon-cry-outline', color: 'error'},
        0.5: {icon: 'mdi-emoticon-cry-outline', color: 'error lighten-1'},
        1: {icon: 'mdi-emoticon-confused-outline', color: 'pink darken-1'},
        1.5: {icon: 'mdi-emoticon-confused-outline', color: 'pink'},
        2: {icon: 'mdi-emoticon-angry-outline', color: 'secondary lighten-1'},
        2.5: {icon: 'mdi-emoticon-angry-outline', color: 'secondary'},
        3: {icon: 'mdi-emoticon-sad-outline', color: 'primary lighten-1'},
        3.5: {icon: 'mdi-emoticon-sad-outline', color: 'primary'},
        4: {icon: 'mdi-emoticon-neutral-outline', color: 'teal lighten-1'},
        4.5: {icon: 'mdi-emoticon-neutral-outline', color: 'teal lighten-1'},
        5: {icon: 'mdi-emoticon-happy-outline', color: 'success'},
      }

      return payload[this.rating] ? payload[this.rating][type] : {icon: 'mdi-face-man', color: 'grey darken-2'}[type]
    },
    changeAppealStatus() {
      this.change_status = true
    },
    doRate() {
      this.$store.dispatch(APPEAL_ITEM_RATE, { appeal_id: this.item_object.id, rating: this.rating })
        .then(() => {
          this.item_object.rating = this.rating
          this.rating = null
          this.show_rate = false
        })
    },
    closeRateDialog() {
      this.show_rate = false
      this.rating = null
    },
    changeStatusCrud() {
      this.publishNewItem(this.item_object.status_type_new)
    },
    closeAppeal() {
      const payload = {
        text: "Підтвердіть закриття звернення.",
        accept_button: true,
        id: 'appeal-close'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    repeatAppeal() {
      const payload = {
        text: "Підтвердіть повторне відкриття звернення.",
        accept_button: true,
        id: 'appeal-repeat'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    autoScroll() {
      const chat = document.getElementById('chat')
      if (chat) {
        const scroll = chat.scrollHeight
        const height = chat.clientHeight

        if (scroll >= height) {
          chat.scrollTo(0, scroll);
        }
      }
    },
    closeDialog() {
      this.$store.commit(APPEAL_CLEAR_COMMENTS)

      this.show_dialog = false

      const vm = this

      setTimeout(() => {
        vm.item_object.id = null
        vm.item_object.files = []
        vm.item_object.text = ''
        vm.item_object.rating = null
        vm.item_object.receiver_name = ''
        vm.item_object.receiver_id = null
        vm.item_object.create_date = null
        vm.item_object.history_status = []
        vm.item_object.message = ''
        vm.item_object.edit = false
        vm.item_object.rating = null
        vm.its_owner = false
      }, 100)
    },
    closeChangeStatusDialog() {
      this.item_object.status_type_new = null
      this.change_status = false
    },
    onAppealClick(payload) {
      this.item_object.id = payload.id
      this.item_object.text = payload.text
      this.item_object.create_date = payload.create_date
      this.item_object.anonymously = payload.anonymously
      this.item_object.receiver_id = payload.receiver_id
      this.item_object.receiver_name = payload.receiver_name
      this.item_object.status_type = payload.status_type
      this.item_object.message = ''
      this.item_object.files = this.copyObject(payload.files)
      this.item_object.history_status = payload.status_history
      this.item_object.edit = false
      this.item_object.rating = payload.rating
      this.item_object.its_owner = payload.owner === this.profile.id

      this.fetch_comments(payload.id)
          .then(response => {
            if (response) {
              this.autoScroll()
            }
          })

      this.show_dialog = true
    },
    publishNewItem(type = 'new') {
      if (!this.newItemReady) return

      if (!this.item_object.id) {

        const formData = new FormData()
        formData.append('text', this.item_object.text)
        formData.append('anonymously', this.item_object.anonymously || false)
        formData.append('receiver_id', this.item_object.receiver_id)
        formData.append('status_type', 'new')
        formData.append('flat_id', this.flat.id)

        this.item_object.files.forEach(item => {
          if (item.binary) {
            formData.append('files', item.binary)
          }
        })

        this.loading = true

        this.$store.dispatch(APPEAL_ITEM_CREATE, formData)
            .then(data => {
              this.item_object.id = data.id
              this.item_object.text = data.text
              this.item_object.create_date = data.create_date
              this.item_object.anonymously = data.anonymously
              this.item_object.receiver_id = data.receiver_id
              this.item_object.receiver_name = data.receiver_name
              this.item_object.status_type = data.status_type
              this.item_object.message = ''
              this.item_object.its_owner = data.owner === this.profile.id

              this.item_object.files = data.files
              this.item_object.history_status = data.status_history
              this.item_object.edit = false

              this.$ws.send_message(Object.assign(data, {ws_type: 'appeal_crud'}))
              this.$store.commit(ALERT_SHOW, {text: 'Звернення успішно опубліковано', color: 'success'})
            })
            .finally(() => {
              this.loading = false
            })
      } else {
        const formData = new FormData()
        formData.append('id_', this.item_object.id)
        formData.append('text', this.item_object.text)
        formData.append('status_type', type)
        formData.append('flat_id', this.flat.id)

        this.item_object.files.forEach(item => {
          if (item.binary) {
            formData.append('files', item.binary)
          } else {
            if (item.delete) {
              formData.append('files_delete', item.id)
            }
          }
        })

        this.loading = true

        this.$store.dispatch(APPEAL_ITEM_UPDATE, formData)
            .then(data => {
              this.item_object.text = data.text
              this.item_object.message = ''
              this.item_object.files = data.files
              this.item_object.history_status = data.status_history
              this.item_object.edit = false
              this.item_object.status_type = data.status_type
              this.item_object.status_type_new = null
              this.item_object.its_owner = data.owner === this.profile.id
              this.change_status = false

              try {
                this.$ws.send_message(Object.assign(data, {ws_type: 'appeal_crud'}))
              } catch {
                console.log('Щось не так із WebSocket...)')
              } finally {
                this.$store.commit(ALERT_SHOW, {text: 'Звернення успішно оновлено', color: 'success'})
              }

              if (type === 'close') {
                setTimeout(() => {
                  this.show_rate = true
                }, 800)
              }
            })
            .finally(() => {
              this.loading = false
            })
      }
    },
    onItemEdit(item) {
      this.onAppealClick(item)
    },
    onItemDelete(item) {
      const payload = {
        text: "Підтвердіть вилучення звернення.",
        accept_button: true,
        id: 'appeal-remove',
        payload: {id: item.id}
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    addComment() {
      if (!this.item_object.message) return ''

      this.$store.dispatch(APPEAL_COMMENT_CREATE,
          {appeal_id: this.item_object.id, text: this.item_object.message}
      )
          .then(response => {
            if (response) {
              try {
                this.$ws.send_message(Object.assign(response, {ws_type: 'appeal_comment'}))
                this.item_object.message = ''
              } catch {
                this.item_object.message = ''
              } finally {
                this.$store.commit(APPEAL_UPDATE_COMMENT_COUNT, {id: this.item_object.id, count: this.comments.length})
              }
              this.autoScroll()
            }
          })
    },
    copyObject(obj) {
      return obj.map(i => {
        return {...i, delete: false}
      })
    },
    changeFile(payload) {
      if (!payload) {
        return
      }

      const max_obj = maxBy(this.item_object.files, i => i.id)
      const max = max_obj ? (max_obj.id || 0) : 0

      payload.forEach((item, idx) => {
        const file_ext = (ext_obj.find(item_ext => item_ext.mime === item.type)) || {ext: 'txt', mime: ''}
        const file_obj = {
          id: (max || 0) + idx + 19999999,
          file_path: item.name,
          file_ext: file_ext.ext,
          file_name: item.name,
          file_name_origin: item.name,
          binary: item,
        }
        this.item_object.files.push(file_obj)
      })
    },
    changeFileOutgoing(payload) {
      if (!payload) {
        return
      }
      const formData = new FormData()
      formData.append('id_', this.item_object.id)

      payload.forEach(item => {
        formData.append('files', item)
      })

      if (formData.has('files')) {
        this.loading = true
        this.$store.dispatch(APPEAL_FILE_UPDATE, formData)
            .then(data => {
              this.item_object.files = data.files
            })
            .finally(() => {
              this.loading = false
            })
      }

    },
    deleteFile(payload) {
      if (payload.binary) {
        const obj_idx = this.item_object.files.indexOf(payload)
        if (payload) {
          this.item_object.files.splice(obj_idx, 1)
        }
      } else {
        payload.delete = !payload.delete
      }
    },
    deleteFileOutgoing(payload) {
      if (!payload.binary) {
        payload.delete = !payload.delete

        const formData = new FormData()
        formData.append('id_', this.item_object.id)
        formData.append('files_delete', payload.id)

        this.loading = true
        this.$store.dispatch(APPEAL_FILE_UPDATE, formData)
            .then(data => {
              this.item_object.files = data.files
            })
            .finally(() => {
              this.loading = false
            })
      }
    },
    getFile(payload) {
      if (payload.binary) {
        this.$store.dispatch(ALERT_SHOW, {
          text: 'Для перегляду спершу слід виконати команду зберегти',
          color: 'secondary'
        })
        return '#'
      }

      return `${BASE_URL_AXIOS}/${payload.file_path}`
    },
    getFileIcon(ext) {
      const file_ext = (ext_obj.find(item_ext => item_ext.ext === ext)) || {ext: 'txt', mime: ''}
      return file_ext.icon || 'mdi-document'
    },
    preview_image(payload) {
      const this_image = this.imageExt(payload.file_ext)
      if (payload.binary) {
        this.$store.dispatch(ALERT_SHOW, {
          text: 'Для перегляду спершу слід виконати команду зберегти',
          color: 'secondary'
        })
        return;
      }

      if (!this_image && payload.file_ext !== 'pdf') {
        return
      }
      if (payload.file_ext === 'pdf') {
        this.is_pdf = true
        this.preview = false
      } else {
        this.preview = true
      }

      this.file_url = `${BASE_URL_AXIOS}/${payload.file_path}`

      this.preview = true
      //
      // staticAPI.get_file(payload.file_path)
      //     .then(response => {
      //       // const type = response.headers['content-type']
      //       const blob = new Blob([response.data])
      //       this.file_url = window.URL.createObjectURL(blob)
      //     })
    },
    imageExt(ext) {
      const image_types = ext_obj.filter(item => item.mime.indexOf('image/') !== -1)
      const obj = image_types.find(item => item.ext === ext)
      return !!obj
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === "appeal-close") {
                if (payload.answer) {
                  this.publishNewItem('close')
                }
              }
              if (payload.id === "appeal-repeat") {
                if (payload.answer) {
                  this.publishNewItem('repeat')
                }
              }
              if (payload.id === "appeal-remove") {
                if (payload.answer) {
                  if (payload.payload) {
                    if (payload.payload.id) {
                      this.$store.dispatch(APPEAL_ITEM_REMOVE, payload.payload.id)
                          .then(response => {
                            if (response) {
                              try {
                                this.$ws.send_message(Object.assign(response, {ws_type: 'appeal_remove'}))
                              } catch {
                                this.$store.commit(ALERT_SHOW, {text: 'Щось не так із SOCKET)', color: 'error'})
                              }
                            }
                          })
                    }
                  }
                }
              }
            }
          }
      )
    },
  },
  watch: {
    socket_data: {
      immediate: true,
      handler(payload) {
        if (payload && this.item) {
          if (payload.data.ws_type === 'appeal_comment'
              && payload.data.vote_id === this.item.id
          ) {
            if (!this.items.find(item => item.id === payload.data.id)) {
              let local_payload = Object.assign({}, payload.data, {self: false})
              this.$store.commit(APPEAL_COMMENT_CREATE, local_payload)
              this.autoScroll()
            }
          }

          if (payload.data.ws_type === 'appeal_crud'
              && payload.data.organization_id === this.organization.id
          ) {
            const obj = this.items.find(item => item.id === payload.data.id)
            if (obj) {
              this.$store.commit(APPEAL_ITEM_UPDATE, payload.data)
              this.$store.commit(ALERT_SHOW, {text: `Дані звернення оновлено)`, color: 'success'})
            } else {
              this.$store.commit(APPEAL_ITEM_CREATE, payload.data)
              this.$store.commit(ALERT_SHOW, {text: `Нове звернення`, color: 'success'})
            }
          }

          if (payload.data.ws_type === 'appeal_remove'
              && payload.data.organization_id === this.organization.id
          ) {
            this.$store.commit(APPEAL_ITEM_REMOVE, payload.data.id)
              .then(response => {
                if (response) {
                  this.$store.commit(ALERT_SHOW, {text: `Звернення вилучено`, color: 'error'})
                }
              })
          }

        }
      }
    },
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.$store.dispatch(SELECT_APPEAL_RECEIVER_GROUP)
          this.fetchAppealData()
        }
      }
    },
  },
  created() {
    this.getCurrentMonth()
        .then(() => {
          this.filters.date_start = beginOfMonth(addMothsToStringDate(this.current_month, -1))
          this.filters.new = true
          this.filters.progress = true
          this.filters.reject = true

          this.$store.dispatch(SELECT_APPEAL_RECEIVER_GROUP)
          this.fetchAppealData()
          this.watch_modal_answer()
        })
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.top-appeal-line {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  flex-wrap: wrap;

  .chip {
    padding: 8px 14px;
    font-size: .9rem;
    color: white;
    border-radius: 4px;
    border: 1px solid;
  }

  .date-receiver {
    line-height: 1.05rem;

    .date {
      font-size: .87rem;
      color: #545454;
    }

    .receiver {
      font-size: .84rem;
      color: #858585;
    }
  }


  .stars {
    display: flex;
    align-items: center;

    .star-value {
      font-size: .96rem;
      font-weight: bold;
      color: #505050;
    }
  }

  .address-line {
    font-size: .84rem;
    margin-top: 4px;
    color: #282828;
  }
}

.text-appeal-line {
  height: 75px;
  padding: 6px 4px;
  font-size: .96rem;
  line-height: 1.3rem;
  color: #7c7c7c;
}

.bottom-appeal-line {
  padding: 8px 4px 4px 4px;
  display: flex;
  align-items: center;

  .bottom-items {
    display: flex;
    align-items: center;

    .bottom-item {
      margin-right: 12px;
      font-size: .9rem;
    }
  }
}

.empty-files {
  height: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 3px dashed #4caf507d;
  font-size: 1rem;
  color: #808080e6;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .image-content {
    height: 90%;
    width: 90%;
    position: relative;
  }
}
</style>